<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 row"
      >
        <b-form-group
          :label="$t('Именование(uz)')"
          label-for="name_uz"
          class="col-12"
        >
          <validation-provider
            #default="{ errors }"
            name="name_uz"
            rules="required"
          >
            <b-form-input
              id="name_uz"
              v-model="form.name_uz"
              :state="errors.length > 3 ? false:null"
              name="name_uz"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Именование(ru)')"
          label-for="name_ru"
          class="col-12"
        >
          <validation-provider
            #default="{ errors }"
            name="name_ru"
            rules="required"
          >
            <b-form-input
              id="name_ru"
              v-model="form.name_ru"
              :state="errors.length > 3 ? false:null"
              name="name_ru"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
  },
  data() {
    return {
      form: {
        id: null,
        name_uz: '',
        name_ru: '',
      },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ cities: 'city/GET_ITEMS' }),
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.title = this.$t('Добавить единицу измерения')

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'resource/storeUnitOfMeasure', updateItem: 'resource/updateUnitOfMeasure',
    }),
  },
}
</script>

<style scoped>

</style>
